.divMember {
  display: grid; }
  .divMember .modalAddIntroMember {
    width: 650px; }

.bodyAddMember .divIntroMember {
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  position: relative;
  margin-bottom: 25px; }
  .bodyAddMember .divIntroMember .labelIntroMember {
    text-align: left;
    padding: 0.25rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    width: 100%; }
  .bodyAddMember .divIntroMember .divIntrosMember {
    flex: 1 1 auto;
    padding: 0.25rem; }
    .bodyAddMember .divIntroMember .divIntrosMember .textareaIntroMember {
      width: 100%;
      resize: none;
      height: 200px;
      text-align: justify;
      font-size: 17px; }
    .bodyAddMember .divIntroMember .divIntrosMember .inputIntroMember {
      font-size: 17px;
      width: 100%;
      height: 40px; }
    .bodyAddMember .divIntroMember .divIntrosMember .displayIMG {
      text-align: center; }
      .bodyAddMember .divIntroMember .divIntrosMember .displayIMG .imgIntroUrl {
        height: 200px;
        width: 50%;
        object-fit: contain; }
      .bodyAddMember .divIntroMember .divIntrosMember .displayIMG .inputIMGMember {
        width: 100px; }
  .bodyAddMember .divIntroMember .divAltIntroMember {
    font-weight: bold;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    position: relative;
    margin-bottom: 25px; }
    .bodyAddMember .divIntroMember .divAltIntroMember .labelAltIntroMember {
      padding: 0.25rem 1.25rem;
      margin-bottom: 0;
      background-color: rgba(0, 0, 0, 0.03);
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      width: 100%;
      text-align: left; }
    .bodyAddMember .divIntroMember .divAltIntroMember .divAltIntrosMember {
      flex: 1 1 auto;
      padding: 0.25rem; }
      .bodyAddMember .divIntroMember .divAltIntroMember .divAltIntrosMember .inputALTMember {
        width: 100%; }

.bodyAddMember .btnIntroMember {
  text-align: right; }
  .bodyAddMember .btnIntroMember .buttonCloseAddMember {
    margin-right: 6px; }
  .bodyAddMember .btnIntroMember .buttonCloseUpdateMember {
    margin-right: 6px; }

.imgMember {
  border: 1px solid; }
  .imgMember .sttKey {
    text-align: center;
    font-size: 17px; }
  .imgMember .tdNameMember {
    text-align: justify;
    font-size: 17px; }
  .imgMember .tdIntroMember {
    width: 30%;
    text-align: justify;
    font-size: 17px; }
    .imgMember .tdIntroMember .pInfoMember {
      margin-bottom: 6px; }
  .imgMember .tdImgIntroMember .imgMemberTD {
    width: 100%;
    height: 100px;
    border: 1px solid;
    object-fit: cover; }
  .imgMember .tdAltMember {
    font-size: 17px; }
  .imgMember .btnActionMember {
    width: 25%;
    text-align: center; }
    .imgMember .btnActionMember .btnUpdateImage {
      margin-right: 6px; }
    .imgMember .btnActionMember .modalIntroMember {
      width: 650px; }
    .imgMember .btnActionMember .divDelMember {
      text-transform: uppercase; }
      .imgMember .btnActionMember .divDelMember .divActionsDelMember {
        text-align: right; }
        .imgMember .btnActionMember .divDelMember .divActionsDelMember .btnCloseDelMember {
          margin-right: 6px; }
  .imgMember .sttMember {
    width: 5%;
    text-align: center; }
  .imgMember .nameMember {
    width: 25%; }
  .imgMember .imgMemberTH {
    width: 10%; }
  .imgMember .altMember {
    width: 160px; }
  .imgMember .actionMember {
    text-align: center; }
  .imgMember .h4DeleteMem {
    width: 100%; }
    .imgMember .h4DeleteMem h4 {
      text-align: center;
      text-transform: uppercase; }
