/* td {
  word-break: break-all;
} */
.btn-select-album {
  margin-top: 10px; }

.wrap-choose-file-btn {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 5px;
  background: #008F30;
  color: white; }
  .wrap-choose-file-btn input {
    display: none; }

.wrap-modal-album {
  display: flex;
  overflow-x: scroll;
  align-items: center;
  padding: 0 !important; }

.wrap-modal-album-item {
  width: 200px;
  border: 1px solid #888;
  position: relative;
  padding: 10px;
  margin: 10px auto 0px 8px; }
  .wrap-modal-album-item img {
    border: 1px solid #888;
    height: 150px; }

.modal-img-evt {
  max-width: 100%;
  padding: 4px;
  margin-top: 4px; }

.fixed-text-area-size {
  min-height: calc(1.5em + 0.75rem + 2px); }

.highlight-evt-name {
  color: red; }

.close-modal-btn {
  color: white !important;
  background-color: #c1c1c1 !important; }

.center-td {
  text-align: center; }

.center-td * {
  margin-left: 5px; }

.center-th th:last-child {
  text-align: center; }

.input-file-max-width {
  width: 100%; }

.fixed-table-event {
  table-layout: fixed; }

.fixed-table-event td {
  width: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle !important; }

.center-modal-title {
  width: 100%;
  text-align: center !important; }

.description-border, .top-content-border {
  border: 1px solid #ced4da;
  padding: 10px;
  border-radius: 5px;
  position: relative; }

.larger-icon {
  font-size: large; }

.btn-close-description {
  position: absolute;
  top: -10px;
  right: -10px;
  background: none;
  border: none; }

.reset-row-margin {
  margin-right: 0px !important;
  margin-top: 0.25rem; }

.reset-row-margin-both {
  margin: 5px 0px !important; }

.reset-row-margin-both button {
  border-left: none; }

.reset-padding-col {
  padding-right: 0px !important; }

@media screen and (max-width: 650px) {
  .wrap-header-table th:not(:last-child):not(:first-child):not(:nth-child(2)) {
    display: none; }
  .wrap-table-data td:not(:last-child):not(:first-child):not(:nth-child(2)) {
    display: none; } }

@media screen and (max-width: 576px) {
  .col-form-label {
    display: none; }
  .reset-row-margin-both button {
    border: none; } }

@media screen and (max-width: 850px) {
  .center-td * {
    margin-left: 0px;
    margin-bottom: 5px; } }

.wrap-event-form .form-container {
  padding: 10px 20px;
  margin-top: 20px; }
  .wrap-event-form .form-container .header-form {
    width: 100%;
    text-align: center;
    margin-bottom: 10px; }
  .wrap-event-form .form-container .wrap-field {
    margin-bottom: 10px; }
    .wrap-event-form .form-container .wrap-field .header-col {
      margin: auto 0; }
      .wrap-event-form .form-container .wrap-field .header-col label {
        margin: 0; }
    .wrap-event-form .form-container .wrap-field .wrap-drop-down button, .wrap-event-form .form-container .wrap-field .wrap-drop-down .dropdown-menu {
      width: 100%;
      text-align: center; }
  .wrap-event-form .form-container .wrap-background-col {
    display: grid; }
    .wrap-event-form .form-container .wrap-background-col .lb-background {
      border: 1px solid black;
      width: 200px;
      max-width: 100%;
      height: 152px;
      position: relative; }
      .wrap-event-form .form-container .wrap-background-col .lb-background img {
        max-width: inherit;
        height: calc(100% - 1px);
        position: absolute; }
      .wrap-event-form .form-container .wrap-background-col .lb-background .mask {
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        position: absolute;
        width: inherit;
        height: inherit;
        top: 0; }
        .wrap-event-form .form-container .wrap-background-col .lb-background .mask .wrap-text {
          position: absolute;
          top: 40%;
          width: inherit;
          text-align: center;
          color: white; }
      .wrap-event-form .form-container .wrap-background-col .lb-background input {
        width: inherit;
        height: inherit;
        position: absolute;
        display: none; }
      .wrap-event-form .form-container .wrap-background-col .lb-background .mask:hover {
        opacity: 1; }
    .wrap-event-form .form-container .wrap-background-col .wrap-header-bgr {
      width: 200px;
      text-align: center; }
  .wrap-event-form .form-container .header-col-content {
    height: 38px; }
  .wrap-event-form .form-container .wrap-editor .tox {
    margin: 0 15px; }
  .wrap-event-form .form-container .wrap-editor .ck-editor {
    width: 100%;
    padding: 0px 15px; }
    .wrap-event-form .form-container .wrap-editor .ck-editor .ck-content {
      height: 350px; }
  .wrap-event-form .form-container .wrap-action-button {
    margin: 10px 0px 0px 0px;
    justify-content: flex-end; }
    .wrap-event-form .form-container .wrap-action-button button:first-child {
      margin-right: 10px; }
