.aboutTermPage .tableTermDisplay {
  border: 1px solid; }
  .aboutTermPage .tableTermDisplay .inputTerm {
    width: 350px; }
  .aboutTermPage .tableTermDisplay .textareaTerm {
    resize: none;
    height: 100px;
    width: 100%; }
  .aboutTermPage .tableTermDisplay .tdTermSTT {
    font-size: 17px; }
  .aboutTermPage .tableTermDisplay .tdTermTitle {
    text-align: justify;
    font-size: 17px; }
  .aboutTermPage .tableTermDisplay .tdTermContent {
    text-align: justify;
    font-size: 17px; }
    .aboutTermPage .tableTermDisplay .tdTermContent .pContentTerm {
      margin-bottom: 6px; }
  .aboutTermPage .tableTermDisplay .tdTermBtn {
    text-align: center; }
    .aboutTermPage .tableTermDisplay .tdTermBtn .buttonUpdateTerm {
      margin-right: 6px; }
    .aboutTermPage .tableTermDisplay .tdTermBtn .modalUpdateTerm {
      width: 650px; }
      .aboutTermPage .tableTermDisplay .tdTermBtn .modalUpdateTerm .divUpdateTerm {
        font-weight: bold;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        position: relative;
        margin-bottom: 25px; }
        .aboutTermPage .tableTermDisplay .tdTermBtn .modalUpdateTerm .divUpdateTerm .labelUpdateTerm {
          text-align: left;
          padding: 0.25rem 1.25rem;
          margin-bottom: 0;
          background-color: rgba(0, 0, 0, 0.03);
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);
          width: 100%; }
        .aboutTermPage .tableTermDisplay .tdTermBtn .modalUpdateTerm .divUpdateTerm .divInputUpdateTerm {
          flex: 1 1 auto;
          padding: 0.25rem; }
          .aboutTermPage .tableTermDisplay .tdTermBtn .modalUpdateTerm .divUpdateTerm .divInputUpdateTerm .inputUpdateTerm {
            font-size: 17px;
            width: 100%;
            height: 40px;
            text-align: justify; }
          .aboutTermPage .tableTermDisplay .tdTermBtn .modalUpdateTerm .divUpdateTerm .divInputUpdateTerm .textUpdateTerm {
            width: 100%;
            resize: none;
            height: 200px;
            text-align: justify;
            font-size: 17px; }
      .aboutTermPage .tableTermDisplay .tdTermBtn .modalUpdateTerm .btnActionsUpdateTerm {
        text-align: right; }
        .aboutTermPage .tableTermDisplay .tdTermBtn .modalUpdateTerm .btnActionsUpdateTerm .btnCloseUpdateTerm {
          margin-right: 6px; }
    .aboutTermPage .tableTermDisplay .tdTermBtn .divDeleteTerm {
      text-transform: uppercase; }
      .aboutTermPage .tableTermDisplay .tdTermBtn .divDeleteTerm .divActionDelTerm {
        text-align: right; }
        .aboutTermPage .tableTermDisplay .tdTermBtn .divDeleteTerm .divActionDelTerm .btnCloseDelTerm {
          margin-right: 6px; }
  .aboutTermPage .tableTermDisplay .thTermSTT {
    width: 5%; }
  .aboutTermPage .tableTermDisplay .thTermContent {
    width: 45%; }
  .aboutTermPage .tableTermDisplay .thTermAction {
    text-align: center;
    width: 25%; }

.divBtnAddTerm {
  display: grid; }
  .divBtnAddTerm .modalAddTerms {
    width: 650px; }
    .divBtnAddTerm .modalAddTerms .divAddTerms {
      font-weight: bold;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0.25rem;
      position: relative;
      margin-bottom: 25px; }
      .divBtnAddTerm .modalAddTerms .divAddTerms .labelAddTerm {
        text-align: left;
        padding: 0.25rem 1.25rem;
        margin-bottom: 0;
        background-color: rgba(0, 0, 0, 0.03);
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        width: 100%; }
      .divBtnAddTerm .modalAddTerms .divAddTerms .divInputAddTerm {
        flex: 1 1 auto;
        padding: 0.25rem; }
        .divBtnAddTerm .modalAddTerms .divAddTerms .divInputAddTerm .inputAddTerm {
          font-size: 17px;
          width: 100%;
          height: 40px;
          text-align: justify; }
        .divBtnAddTerm .modalAddTerms .divAddTerms .divInputAddTerm .textAddTerm {
          width: 100%;
          resize: none;
          height: 200px;
          text-align: justify;
          font-size: 17px; }
    .divBtnAddTerm .modalAddTerms .btnActionAddTerm {
      text-align: right; }
      .divBtnAddTerm .modalAddTerms .btnActionAddTerm .btnCloseAddTerm {
        margin-right: 6px; }
