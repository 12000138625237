.divAddUSBeautyComponent {
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  position: relative;
  margin-bottom: 25px; }
  .divAddUSBeautyComponent .labelAddUSBeautyComponent {
    text-align: left;
    padding: 0.25rem 0.75rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    width: 100%; }
  .divAddUSBeautyComponent .divAddUSBeauty {
    flex: 1 1 auto;
    padding: 0.25rem; }
    .divAddUSBeautyComponent .divAddUSBeauty .textUSBeautyComponent {
      font-size: 17px;
      width: 100%;
      height: 40px;
      margin-left: unset; }
  .divAddUSBeautyComponent #dropdown-menu-align-right {
    padding: 0.25rem;
    width: 100%; }
  .divAddUSBeautyComponent .dropdown-menu.show {
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    font-weight: bold; }

.titleAddUSBeautyComponent {
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  position: relative;
  margin-bottom: 25px; }
  .titleAddUSBeautyComponent .clearFormText2 {
    width: 100%;
    float: unset;
    display: inline-flex; }
    .titleAddUSBeautyComponent .clearFormText2 .labelAddUSBeautyComponent {
      text-align: left;
      padding: 0.25rem 0.75rem;
      margin-bottom: 0;
      background-color: rgba(0, 0, 0, 0.03);
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      width: 100%; }
    .titleAddUSBeautyComponent .clearFormText2 #remove {
      margin-top: 0; }
  .titleAddUSBeautyComponent .formAddUSBeautyComponent {
    width: 100%;
    margin-left: 0;
    margin-bottom: 0;
    float: unset;
    flex: 1 1 auto;
    padding: 0.25rem;
    display: inline-flex; }
    .titleAddUSBeautyComponent .formAddUSBeautyComponent .divFormAddUSBeautyComponent {
      width: 35%; }
      .titleAddUSBeautyComponent .formAddUSBeautyComponent .divFormAddUSBeautyComponent .imgAddUSBeautyComponent {
        float: unset;
        height: 150px;
        object-fit: contain;
        width: 100%; }
    .titleAddUSBeautyComponent .formAddUSBeautyComponent .contImgAddUSBeautyComponent {
      margin-top: 0;
      width: 65%;
      float: unset; }
      .titleAddUSBeautyComponent .formAddUSBeautyComponent .contImgAddUSBeautyComponent .divContImgAddUSBeautComponent {
        display: inline-flex;
        width: 100%; }
        .titleAddUSBeautyComponent .formAddUSBeautyComponent .contImgAddUSBeautyComponent .divContImgAddUSBeautComponent .altAddUSBeautyComponent {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 0; }
        .titleAddUSBeautyComponent .formAddUSBeautyComponent .contImgAddUSBeautyComponent .divContImgAddUSBeautComponent .inputAltAddUSBeautyComponent {
          padding: 0;
          margin: 0; }
    .titleAddUSBeautyComponent .formAddUSBeautyComponent .contAddUSBeautyComponent {
      width: 100%;
      margin: 0; }
    .titleAddUSBeautyComponent .formAddUSBeautyComponent .textareaAddUSBeautyComponent {
      width: 100%;
      float: unset;
      margin-right: 0;
      resize: none;
      height: 150px; }

.divUpdateUSBeautyComponent {
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  position: relative;
  margin-bottom: 25px; }
  .divUpdateUSBeautyComponent .labelUpdateUSBeautyComponent {
    text-align: left;
    padding: 0.25rem 0.75rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    width: 100%; }
  .divUpdateUSBeautyComponent .divUpdateUSBeauty {
    flex: 1 1 auto;
    padding: 0.25rem; }
    .divUpdateUSBeautyComponent .divUpdateUSBeauty .textUSBeautyComponent {
      font-size: 17px;
      width: 100%;
      height: 40px;
      margin-left: unset; }
  .divUpdateUSBeautyComponent #dropdown-menu-align-right {
    padding: 0.25rem;
    width: 100%; }
  .divUpdateUSBeautyComponent .dropdown-menu.show {
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    font-weight: bold; }

.titleUpdateUSBeautyComponent {
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  position: relative;
  margin-bottom: 25px; }
  .titleUpdateUSBeautyComponent .clearUpdateFormText2 {
    width: 100%;
    float: unset;
    display: inline-flex; }
    .titleUpdateUSBeautyComponent .clearUpdateFormText2 .labelUpdateUSBeautyComponent {
      text-align: left;
      padding: 0.25rem 0.75rem;
      margin-bottom: 0;
      background-color: rgba(0, 0, 0, 0.03);
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      width: 100%; }
    .titleUpdateUSBeautyComponent .clearUpdateFormText2 #remove {
      margin-top: 0; }
  .titleUpdateUSBeautyComponent .formUpdateUSBeautyComponent {
    width: 100%;
    margin-left: 0;
    margin-bottom: 0;
    float: unset;
    flex: 1 1 auto;
    padding: 0.25rem;
    display: inline-flex; }
    .titleUpdateUSBeautyComponent .formUpdateUSBeautyComponent .divFormUpdateUSBeautyComponent {
      width: 35%; }
      .titleUpdateUSBeautyComponent .formUpdateUSBeautyComponent .divFormUpdateUSBeautyComponent .imgUpdateUSBeautyComponent {
        float: unset;
        height: 150px;
        object-fit: contain;
        width: 100%; }
    .titleUpdateUSBeautyComponent .formUpdateUSBeautyComponent .contImgUpdateUSBeautyComponent {
      margin-top: 0;
      width: 65%;
      float: unset; }
      .titleUpdateUSBeautyComponent .formUpdateUSBeautyComponent .contImgUpdateUSBeautyComponent .divContImgUpdateUSBeautComponent {
        display: inline-flex;
        width: 100%; }
        .titleUpdateUSBeautyComponent .formUpdateUSBeautyComponent .contImgUpdateUSBeautyComponent .divContImgUpdateUSBeautComponent .altUpdateUSBeautyComponent {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 0; }
        .titleUpdateUSBeautyComponent .formUpdateUSBeautyComponent .contImgUpdateUSBeautyComponent .divContImgUpdateUSBeautComponent .inputAltUpdateUSBeautyComponent {
          padding: 0;
          margin: 0; }
    .titleUpdateUSBeautyComponent .formUpdateUSBeautyComponent .contUpdateUSBeautyComponent {
      width: 100%;
      margin: 0; }
    .titleUpdateUSBeautyComponent .formUpdateUSBeautyComponent .textareaUpdateUSBeautyComponent {
      width: 100%;
      float: unset;
      margin-right: 0;
      resize: none;
      height: 150px; }
