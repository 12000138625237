.addFAQ {
  display: grid; }
  .addFAQ .modalAddFAQs {
    width: 650px; }
    .addFAQ .modalAddFAQs .divAddFAQs {
      font-weight: bold;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0.25rem;
      position: relative;
      margin-bottom: 25px; }
      .addFAQ .modalAddFAQs .divAddFAQs .titleAddFAQ {
        padding: 0.25rem 1.25rem;
        margin-bottom: 0;
        background-color: rgba(0, 0, 0, 0.03);
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        width: 100%; }
      .addFAQ .modalAddFAQs .divAddFAQs .textAddFAQ {
        flex: 1 1 auto;
        padding: 0.25rem; }
        .addFAQ .modalAddFAQs .divAddFAQs .textAddFAQ .inputAddFAQ {
          width: 100%;
          text-align: justify;
          font-size: 17px; }
        .addFAQ .modalAddFAQs .divAddFAQs .textAddFAQ .resizeTextareaFAQ {
          width: 100%;
          resize: none;
          height: 200px;
          text-align: justify;
          font-size: 17px; }
    .addFAQ .modalAddFAQs .btnFAQAction {
      text-align: right; }
      .addFAQ .modalAddFAQs .btnFAQAction .buttonCloseAddFAQs {
        margin-right: 6px; }

.tableFAQsData {
  border: 1px solid; }
  .tableFAQsData .thFAQsSTT {
    width: 5%; }
  .tableFAQsData .thAnsFAQs {
    width: 40%; }
  .tableFAQsData .thFAQsAction {
    width: 25%;
    text-align: center; }
  .tableFAQsData .tdFAQsSTT {
    text-align: center;
    font-size: 17px; }
  .tableFAQsData .tdFQAsQues {
    text-align: justify;
    font-size: 17px; }
  .tableFAQsData .tdAnsFAQs {
    text-align: justify;
    font-size: 17px; }
    .tableFAQsData .tdAnsFAQs .pFAQs {
      margin-bottom: 6px; }
  .tableFAQsData .tdFAQsAction {
    text-align: center; }
    .tableFAQsData .tdFAQsAction .buttonUpdateFAQs {
      margin-right: 6px; }
    .tableFAQsData .tdFAQsAction .modalUpdateFAQs {
      width: 650px; }
      .tableFAQsData .tdFAQsAction .modalUpdateFAQs .divUpdateFAQs {
        font-weight: bold;
        position: relative;
        margin-bottom: 25px;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem; }
        .tableFAQsData .tdFAQsAction .modalUpdateFAQs .divUpdateFAQs .labelUpdateFAQs {
          text-align: left;
          margin-bottom: 0px;
          background-color: rgba(0, 0, 0, 0.03);
          width: 100%;
          padding: 0.25rem 1.25rem;
          border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
        .tableFAQsData .tdFAQsAction .modalUpdateFAQs .divUpdateFAQs .textUpdateFAQs {
          flex: 1 1 auto;
          padding: 0.25rem; }
          .tableFAQsData .tdFAQsAction .modalUpdateFAQs .divUpdateFAQs .textUpdateFAQs .inputUpdateFAQ {
            width: 100%;
            text-align: justify;
            font-size: 17px; }
          .tableFAQsData .tdFAQsAction .modalUpdateFAQs .divUpdateFAQs .textUpdateFAQs .resizeUpdateFAQ {
            width: 100%;
            resize: none;
            height: 300px;
            text-align: justify;
            font-size: 17px; }
      .tableFAQsData .tdFAQsAction .modalUpdateFAQs .btnUpdateFAQs {
        text-align: right; }
        .tableFAQsData .tdFAQsAction .modalUpdateFAQs .btnUpdateFAQs .buttonCloseFAQs {
          margin-right: 6px; }
    .tableFAQsData .tdFAQsAction .divDelFAQs {
      text-transform: uppercase; }
      .tableFAQsData .tdFAQsAction .divDelFAQs .btnDelFAQs {
        text-align: right; }
        .tableFAQsData .tdFAQsAction .divDelFAQs .btnDelFAQs .btnCancelFAQs {
          margin-right: 6px; }
