.wrap-tour-schedule .fixed-table-tour {
  table-layout: fixed; }

.wrap-tour-schedule .fixed-table-tour td {
  width: 25%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle !important; }

.wrap-tour-schedule .table-header-row th:last-child {
  text-align: center; }

.wrap-tour-schedule .center-actions-td {
  text-align: center; }
  .wrap-tour-schedule .center-actions-td .actions-parent-div {
    display: inline-flex; }
    .wrap-tour-schedule .center-actions-td .actions-parent-div button:last-child {
      margin-left: 5px; }

.wrap-tour-schedule .open-modal-btn {
  margin-bottom: 10px; }

@media (max-width: 768px) {
  .wrap-tour-schedule .center-actions-td .actions-parent-div {
    display: block; }
    .wrap-tour-schedule .center-actions-td .actions-parent-div button {
      width: 80px; }
    .wrap-tour-schedule .center-actions-td .actions-parent-div button:last-child {
      margin-left: 0px;
      margin-top: 8px; } }
