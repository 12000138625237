.wrap-popular-tour .fromValidated {
  margin-left: 10px;
  width: 372px;
  border: 1px solid #d93025; }

.wrap-popular-tour .fixed-table-tour td {
  vertical-align: middle !important; }

.wrap-popular-tour .table-header-row th:last-child {
  text-align: center; }

.wrap-popular-tour .center-actions-td {
  text-align: center; }
  .wrap-popular-tour .center-actions-td .actions-parent-div {
    display: inline-flex; }
    .wrap-popular-tour .center-actions-td .actions-parent-div span:last-child {
      margin-left: 5px; }
    .wrap-popular-tour .center-actions-td .actions-parent-div .divActionUpdatePopuTour .textareaUpdatePopuTour {
      resize: none;
      height: 137px; }

@media (max-width: 495px) {
  .wrap-popular-tour .center-actions-td .actions-parent-div {
    display: block; }
    .wrap-popular-tour .center-actions-td .actions-parent-div span:last-child {
      margin-left: 0px; }
    .wrap-popular-tour .center-actions-td .actions-parent-div span button {
      margin-top: 8px; } }

.wrap-modal-popular-tour .invalid-input-popular-tour {
  border: 1px solid #d93025 !important; }
