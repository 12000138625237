.divIntroduce {
  margin-bottom: 25px; }
  .divIntroduce .textIntro .textArea {
    border: 1px solid; }
    .divIntroduce .textIntro .textArea .textAddFAQ {
      max-width: none; }
      .divIntroduce .textIntro .textArea .textAddFAQ .textAreaIntro {
        height: 150px;
        resize: none; }
    .divIntroduce .textIntro .textArea .divTextIntro {
      margin: 20px;
      font-weight: bold;
      position: relative;
      margin-bottom: 25px;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.125);
      border-image: initial;
      border-radius: 0.25rem; }
      .divIntroduce .textIntro .textArea .divTextIntro .labelTextIntro {
        margin-bottom: 0px;
        background-color: rgba(0, 0, 0, 0.03);
        width: 100%;
        padding: 0.25rem 1.25rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
      .divIntroduce .textIntro .textArea .divTextIntro .divTextAreaIntro {
        flex: 1 1 auto;
        padding: 0.25rem; }
        .divIntroduce .textIntro .textArea .divTextIntro .divTextAreaIntro .textAreaIntro {
          width: 100%;
          resize: none;
          height: 200px;
          text-align: justify;
          font-size: 17px; }
    .divIntroduce .textIntro .textArea .buttonAreaUpdate {
      float: right;
      margin-right: 20px;
      margin-bottom: 20px; }
      .divIntroduce .textIntro .textArea .buttonAreaUpdate .modalTextUpdateIntro {
        width: 650px; }
        .divIntroduce .textIntro .textArea .buttonAreaUpdate .modalTextUpdateIntro .divUpdateIntro {
          font-weight: bold;
          position: relative;
          margin-bottom: 25px;
          border-width: 1px;
          border-style: solid;
          border-color: rgba(0, 0, 0, 0.125);
          border-radius: 0.25rem; }
          .divIntroduce .textIntro .textArea .buttonAreaUpdate .modalTextUpdateIntro .divUpdateIntro .labelUpdateIntro {
            margin-bottom: 0px;
            background-color: rgba(0, 0, 0, 0.03);
            width: 100%;
            padding: 0.25rem 1.25rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
          .divIntroduce .textIntro .textArea .buttonAreaUpdate .modalTextUpdateIntro .divUpdateIntro .divTextAreaUpdateIntro {
            flex: 1 1 auto;
            padding: 0.25rem; }
            .divIntroduce .textIntro .textArea .buttonAreaUpdate .modalTextUpdateIntro .divUpdateIntro .divTextAreaUpdateIntro .textAreaUpdateIntro {
              width: 100%;
              resize: none;
              height: 200px;
              text-align: justify;
              font-size: 17px; }
        .divIntroduce .textIntro .textArea .buttonAreaUpdate .modalTextUpdateIntro .btnTextIntro {
          text-align: right; }
          .divIntroduce .textIntro .textArea .buttonAreaUpdate .modalTextUpdateIntro .btnTextIntro .btnCloseUpdateIntro {
            margin-right: 6px; }
