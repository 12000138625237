.textarea {
  width: 100% !important;
  border-color: #888;
  border-radius: 4px;
  padding: 5px; }

.larger-textarea {
  height: 200px; }

.myFile {
  width: auto;
  border: none; }

.img-slide-background {
  width: 350px;
  height: 200px;
  object-fit: cover;
  border: grey solid 2px; }

.img-tour {
  width: 250px;
  height: 150px;
  border: grey solid 2px; }

.img-event {
  width: 250px;
  height: 250px;
  border: grey solid 2px; }

.imgUrl {
  width: 300px;
  height: auto; }

input {
  width: 300px; }

.listImage {
  width: 70px !important;
  height: auto;
  margin: 20px; }

.borderInput {
  border: 1px solid black; }

.listSearch {
  border: 1px solid aqua;
  background-color: #f2e6e6; }

.listImageSelect {
  width: 60px !important;
  height: auto;
  margin: 10px; }

.listSelect {
  margin-top: 30px;
  border: 1px solid red;
  background-color: #c0e5c5; }

.listSelectButton {
  float: right;
  padding: 39px 18px 10px 20px; }

.container {
  border: 1px solid #E9EBEE;
  margin-bottom: 20px; }

.fixBottonImage {
  display: none; }
