.sidebar {
  margin: 0;
  padding: 0;
  width: 200px;
  background-color: #f1f1f1;
  position: fixed;
  height: 100%;
  overflow: auto; }
  .sidebar a {
    display: block;
    color: black;
    padding: 16px;
    text-decoration: none; }
    .sidebar a.active {
      background-color: #555555;
      color: white; }
    .sidebar a:hover:not(.active) {
      background-color: #555;
      color: white; }

.content {
  margin-left: 200px; }

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative; }
    .sidebar a {
      float: left; }
  .content {
    margin-left: 0; } }

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none; } }
