.parent-label-border label {
  border: 1px solid black; }

.parent-label-border label:last-child {
  border-left: none; }

.infor {
  width: 100%;
  height: 50px;
  float: left; }

.infor-label {
  margin-left: 5px;
  width: 150px;
  height: 50px;
  float: left;
  font-size: 15px;
  font-weight: bold; }

.infor-div {
  padding-left: 7px;
  margin-left: 10px;
  width: 300px;
  height: 50px;
  float: left;
  border: 1.5px solid lightgray; }

.infor {
  width: 100%;
  height: 75px;
  float: left; }

.infor-label-note {
  margin-left: 5px;
  width: 150px;
  height: 50px;
  float: left;
  font-size: 15px;
  font-weight: bold; }

.infor-div-note {
  padding-left: 7px;
  margin-left: 10px;
  width: 300px;
  height: 75px;
  float: left;
  border: 1.5px solid lightgray; }

.modal-footer {
  justify-content: flex-end;
  padding: 2rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
