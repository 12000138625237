.wrap-login-form {
  /* Full-width input fields */ }
  .wrap-login-form .main-panel {
    margin-left: 200px !important; }
  .wrap-login-form input[type=text], .wrap-login-form input[type=password] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box; }

@media screen and (max-width: 700px) {
  .wrap-login-form .main-panel {
    margin-left: 0 !important; } }
